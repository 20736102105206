import { useFormContext, Controller } from 'react-hook-form';
import { TextField, TextFieldProps } from '@mui/material';

type Props = TextFieldProps & {
  name: string;
};

export default function RHFTextField({ name, helperText, ...other }: Props) {
  const { control } = useFormContext();

  const formatFieldValue = (value: any): string => {
    if (value === null || value === undefined) {
      return '';
    }
    if (typeof value === 'number') {
      return value === 0 ? '' : value.toString();
    }
    if (typeof value === 'object') {
      try {
        if (value instanceof Date) {
          return value.toISOString();
        }
        return JSON.stringify(value);
      } catch {
        return '';
      }
    }
    return String(value);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const fieldProps = {
          ...field,
          value: formatFieldValue(field.value),
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            try {
              field.onChange(e);
            } catch (err) {
              console.error('Error in TextField onChange:', err);
              field.onChange(e.target.value);
            }
          },
        };

        return (
          <TextField
            {...fieldProps}
            fullWidth
            error={!!error}
            helperText={error ? error?.message : helperText}
            {...other}
          />
        );
      }}
    />
  );
}
